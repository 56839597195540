
import { Component, Vue } from "vue-property-decorator";
import AuthService from "../../services/auth-service";
import { isHandled_AuthToken } from '@/utils/handleAuthToken'
import UserManagementService from '@/services/user-management'
import utils from "@/utils/utils"

@Component({
  components: {},
})
export default class Employee extends Vue {
  private data: any[] = [];
  private title: string = "";
  form: any = {};
  error: any = "";
  public routes = [];
  public items = [];
  showPassword = false;

  handleSubmit() {
    // this.error = ""
    const error_list = utils.getEmptyKeys(this.form, ['username', 'password'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      this.error = "";
      AuthService.login(this.form)
        .then((response) => {
          // if (response) {
          //   localStorage.setItem("jwt", response.data.token);
          //   if (localStorage.getItem("jwt") != null) {
          //     this.$emit("loggedIn");
          //     if (this.$route.params.nextUrl != null) {
          //       this.$router.push(this.$route.params.nextUrl);
          //     } else {
          //       this.$router.push("/hrm/employee/employee-list");
          //     }
          //   }
          // }
          if (response.data.token) {
            // if (this.form.rememberMe) {
            //   if (this.form.username && this.form.password) {
            //     this.setCookie('rememberedUsername', this.form.username);
            //     this.setCookie('rememberedPassword', this.form.password);
            //   }
            // } else {
            //   this.clearCookie('rememberedUsername');
            //   this.clearCookie('rememberedPassword');
            // }
            localStorage.setItem('jwt', response.data.token)
            if (isHandled_AuthToken(response.data.token)) {
              this.$emit("loggedIn");
              this.getRoutes();
              setTimeout(() => {
                const localRoutes: any = localStorage.getItem('route');
                const authorizedRoutes = JSON.parse(localRoutes);

                if (authorizedRoutes && authorizedRoutes.length > 0) {
                  const firstAuthorizedRoute = authorizedRoutes[0];
                  this.$router.push(firstAuthorizedRoute);
                }
                // else {
                //   this.$router.push("/");
                // }
              }, 1000);
              // if (this.$route.params.nextUrl != null) {
              //   this.$router.push(this.$route.params.nextUrl);
              // } else {
              //   this.$router.push("/hrm/employee/employee-list");
              // }
            }
          }
        })
        .catch((e) => {
          this.error = "Invalid Credentials";
          console.log(e);
        });
    }
  }
  getRoutes() {
    UserManagementService.get_routes()
      .then((res) => {
        // this.routes = res.data.data;
        const localRoutes = JSON.stringify(res.data.data)
        localStorage.setItem('route', localRoutes)
      })
      .catch((e) => {
        console.log(e);
      });
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  // setCookie(name: any, value: any) {
  //   const expires = new Date();
  //   expires.setFullYear(expires.getFullYear() + 1);
  //   document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=/; secure;`;
  // }
  // clearCookie(name: any) {
  //   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  // }
  // created() {
  //   const rememberedUsername = this.getCookie('rememberedUsername');
  //   const rememberedPassword = this.getCookie('rememberedPassword');

  //   if (rememberedUsername !== null && rememberedPassword !== null) {
  //     this.form.username = rememberedUsername;
  //     this.form.password = rememberedPassword;
  //     this.form.rememberMe = true;
  //   }
  // }
  // getCookie(name: any) {
  //   const value = `; ${document.cookie}`;
  //   const parts: any = value.split(`; ${name}=`);
  //   if (parts.length === 2) {
  //     return decodeURIComponent(parts.pop().split(';').shift());
  //   }
  //   return null;
  // }

  mounted() { }
}
